import { Controller } from '@hotwired/stimulus'

class CustomOverlay extends google.maps.OverlayView {
    constructor(position, content, map) {
        super()
        this.position = position
        this.content = content
        this.div = null
        this.setMap(map) // Reference to the map instance
    }

    onAdd() {
        this.div = document.createElement('div')
        this.div.style.position = 'absolute'
        this.div.style.backgroundColor = 'white'
        this.div.style.color = 'black'
        this.div.style.borderRadius = '4px'
        this.div.style.padding = '4px'
        this.div.style.fontSize = '12px'
        this.div.style.fontFamily = 'Arial, sans-serif'
        this.div.innerHTML = this.content

        this.div.classList.add('tooltips')

        const panes = this.getPanes()
        panes.floatPane.appendChild(this.div)
    }

    draw() {
        const overlayProjection = this.getProjection()
        const position = overlayProjection.fromLatLngToDivPixel(this.position)

        // Center horizontally
        const offsetX = -this.div.offsetWidth / 2 - 2 // slightly moving the square to the left
        this.div.style.left = position.x + offsetX + 'px'

        // Position above the marker
        const offsetY = this.div.offsetHeight + 10 // 35px for additional spacing
        this.div.style.top = position.y - offsetY + 'px'
    }

    onRemove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div)
            this.div = null
        }
    }
    // New method to change the background color
    setBackgroundColor(color) {
        if (this.div) {
            this.div.style.backgroundColor = color
        }
    }
}

export default class extends Controller {
    static targets = ['coordinates']

    initialize() {
        this.markers = {} // Initialize an empty object to store markers
        this.customOverlays = {} // Initialize an empty object to store custom overlays
        this.polygons = {} // Will hold the drawn district polygons
    }

    connect() {
        if (typeof google !== 'undefined') {
            this.renderMap()

            this.updateInterval = setInterval(() => {
                this.updateMarkers()
            }, 5000) // interval for 5 seconds
        } else {
            // console.log('Google Maps API is not loaded.')
        }
    }

    disconnect() {
        clearInterval(this.updateInterval)
    }

    async renderMap() {
        const lat = -20.253752868523762
        const lon = 57.57524372382422
        const coordinates = { lat: lat, lng: lon }

        this.map = new google.maps.Map(this.coordinatesTarget, {
            center: coordinates,
            zoom: 10.5,
            streetViewControl: false,
            mapTypeControl: false
        })

        // Draw district boundaries and store them
        this.polygons = this.drawDistrictBoundaries(this.map)

        let markers_data = await this.fetch_data()
        let markers = markers_data.markers

        markers.forEach((marker_data) => {
            if (
                (marker_data.doctorStatus != 'offline' ||
                    marker_data.doctorStatus != 'off_duty') &&
                Array.isArray(marker_data.patients) &&
                marker_data.patients.length >= 1
            ) {
                const coordinates = {
                    lat: parseFloat(marker_data.lat),
                    lng: parseFloat(marker_data.lng)
                }

                let iconUrl
                const doctorStatusResult =
                    marker_data.doctorStatus.toLowerCase()
                if (
                    doctorStatusResult === 'offline' ||
                    doctorStatusResult === 'off_duty'
                ) {
                    iconUrl = '/assets/map-marker-offline.svg'
                } else {
                    iconUrl = '/assets/map-marker-online.svg'
                }

                const customIcon = {
                    url: iconUrl,
                    scaledSize: new google.maps.Size(60, 33.75)
                }

                let marker = new google.maps.Marker({
                    position: coordinates,
                    map: this.map,
                    icon: customIcon
                })

                // Store the marker using doctor's name as the key
                this.markers[marker_data.doctor] = marker
                const regionData = this.tooltipsColorCoordinates(coordinates)
                this.checkAndUpdateRegion(
                    marker_data.doctorId,
                    regionData.region,
                    marker_data.currentRegion
                )

                const custom_username = marker_data.doctorUsername
                    ? marker_data.doctorUsername
                    : 'N/A'

                const contentString = `
                <div id="tooltip-${marker_data.doctorId}" style="color: black; position:relative;">
                  <ul style="list-style: none; padding: 0; margin: 0;">
                    <li><p style="font-size: 10px; margin:0;">${custom_username}</p></li>
                  </ul>
                </div>
                `

                // Creating and using the custom overlay
                const newOverlay = new CustomOverlay(
                    marker.getPosition(),
                    contentString,
                    this.map
                )
                this.customOverlays[marker_data.doctor] = newOverlay
            }
        })
    }

    // New method to update markers based on latest data
    async updateMarkers() {
        try {
            let markers_data = await this.fetch_data()
            let markers = markers_data.markers
            markers.forEach((marker_data) => {
                // IF STATEMENT TO KNOW IF THE DOCTOR IS OFFLINE AND HAS NO ACTIVE BOOKING FOR BOOKING OF THE DAY
                if (
                    (marker_data.doctorStatus === 'offline' ||
                        marker_data.doctorStatus === 'off_duty') &&
                    Array.isArray(marker_data.patients) &&
                    marker_data.patients.length === 0
                ) {
                    const doctorName = marker_data.doctor
                    if (this.markers[doctorName]) {
                        this.markers[doctorName].setMap(null) // Remove the marker from the map
                        delete this.markers[doctorName] // Delete the marker from the markers object
                    }
                    if (this.customOverlays[doctorName]) {
                        this.customOverlays[doctorName].setMap(null) // Remove the custom overlay from the map
                        delete this.customOverlays[doctorName] // Delete the overlay from the customOverlays object
                    }
                } else {
                    const doctorName = marker_data.doctor
                    const newLat = parseFloat(marker_data.lat)
                    const newLng = parseFloat(marker_data.lng)
                    const newPosition = { lat: newLat, lng: newLng } // Updated position

                    const regionData =
                        this.tooltipsColorCoordinates(newPosition)
                    this.checkAndUpdateRegion(
                        marker_data.doctorId,
                        regionData.region,
                        marker_data.currentRegion
                    )

                    const custom_username = marker_data.doctorUsername
                        ? marker_data.doctorUsername
                        : 'N/A'

                    const contentString = `
                    <div id="tooltip-${marker_data.doctorId}" style="color: black; position:relative;">
                      <ul style="list-style: none; padding: 0; margin: 0;">
                        <li><p style="font-size: 10px; margin:0;">${custom_username}</p></li>
                      </ul>
                    </div>
                    `

                    // Check if the marker already exists
                    if (this.markers[doctorName]) {
                        // Update marker position and icon
                        let iconUrl
                        const doctorStatusResult =
                            marker_data.doctorStatus.toLowerCase()
                        if (
                            doctorStatusResult === 'offline' ||
                            doctorStatusResult === 'off_duty'
                        ) {
                            iconUrl = '/assets/map-marker-offline.svg'
                        } else {
                            iconUrl = '/assets/map-marker-online.svg'
                        }
                        const customIcon = {
                            url: iconUrl,
                            scaledSize: new google.maps.Size(60, 33.75)
                        }

                        this.markers[doctorName].setPosition(newPosition)
                        this.markers[doctorName].setIcon(customIcon)

                        if (this.customOverlays[marker_data.doctor]) {
                            this.customOverlays[marker_data.doctor].setMap(null) // Remove the old overlay
                        }

                        const newOverlay = new CustomOverlay(
                            this.markers[marker_data.doctor].getPosition(),
                            contentString,
                            this.map
                        )
                        this.customOverlays[marker_data.doctor] = newOverlay
                    } else {
                        // Create a new marker if it doesn't exist
                        const newMarker = new google.maps.Marker({
                            position: newPosition,
                            map: this.map
                        })

                        let iconUrl
                        const doctorStatusResult =
                            marker_data.doctorStatus.toLowerCase()
                        if (
                            doctorStatusResult === 'offline' ||
                            doctorStatusResult === 'off_duty'
                        ) {
                            iconUrl = '/assets/map-marker-offline.svg'
                        } else {
                            iconUrl = '/assets/map-marker-online.svg'
                        }
                        const customIcon = {
                            url: iconUrl,
                            scaledSize: new google.maps.Size(60, 33.75)
                        }
                        newMarker.setIcon(customIcon)

                        const newOverlay = new CustomOverlay(
                            newPosition,
                            contentString,
                            this.map
                        )

                        this.markers[doctorName] = newMarker
                        this.customOverlays[doctorName] = newOverlay
                    }
                }
            })
        } catch (error) {
            console.error('Error updating markers:', error)
        }
    }

    // Just to format the status
    formatStatus(status) {
        return status
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    formatWord(word) {
        // Split the input string into an array of words based on uppercase letters
        const words = word.split(/(?=[A-Z])/)

        // Capitalize the first letter of each word
        const capitalizedWords = words.map(
            (w) => w.charAt(0).toUpperCase() + w.slice(1)
        )
        // Join them with a hyphen
        const formattedWord = capitalizedWords.join('-')

        return formattedWord
    }

    async fetch_data() {
        const url = '/fetch_markers'
        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.status}`
                )
            }
            const data = await response.json()
            if (data.error) {
                throw new Error(`Server error: ${data.error}`)
            }
            return data
        } catch (error) {
            console.error('Error:', error)
            throw error // Rethrow the error to indicate failure
        }
    }

    tooltipsColorCoordinates(coordinates) {
        // Determine region by checking all polygons
        let data = { colorUrl: 'outside_polygon_color_url', region: 'outside' }

        for (const [districtName, polygon] of Object.entries(this.polygons)) {
            if (
                google.maps.geometry.poly.containsLocation(
                    new google.maps.LatLng(coordinates.lat, coordinates.lng),
                    polygon
                )
            ) {
                data.colorUrl = 'inside_polygon_color_url'
                data.region = districtName // e.g. "west", "north", etc.
                break
            }
        }
        // console.log('data color:', data)
        return data
    }

    async checkAndUpdateRegion(doctorId, newRegion, currentRegion) {
        if (currentRegion !== newRegion) {
            try {
                const response = await fetch(
                    `/doctors/${doctorId}/update_region`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': document.querySelector(
                                'meta[name="csrf-token"]'
                            ).content
                        },
                        body: JSON.stringify({ user: { region: newRegion } })
                    }
                )

                if (!response.ok) {
                    throw new Error(
                        `Failed to update region: ${response.statusText}`
                    )
                }

                // const result = await response.json()
                // console.log(
                //     `Region updated for doctor ${doctorId}: ${result.message}`
                // )
            } catch (error) {
                console.error(
                    `Error updating region for doctor ${doctorId}:`,
                    error
                )
            }
        }
    }

    drawDistrictBoundaries(map) {
        const districtCoordinates = {
            north: [
                { lat: -20.038154660405645, lng: 57.68496220893801 }, // amber island
                { lat: -20.116382691420796, lng: 57.60657209403735 }, // Mon gout
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // Near Waters's edge banquet hall
                { lat: -20.12059975965392, lng: 57.49878568604895 } // Tombeau bay
            ],
            northEast: [
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // Near Waters's edge banquet hall
                // { lat: -20.153185629902676, lng: 57.545726997165175 }, // Long mountain
                // { lat: -20.21473330935444, lng: 57.532680733310045 }, // St Pierre
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.312118, lng: 57.744994 }, // near belle rive
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.208289665792627, lng: 57.789142814883306 } // palmar
            ],
            east: [
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.30220479564131, lng: 57.650200334640594 }, // Near Motagne Blance
                { lat: -20.323263830267752, lng: 57.70654322812745 }, // Domaine de l'etoile
                { lat: -20.30220479564131, lng: 57.77921739508874 }, // quatre soeurs
                { lat: -20.323263830267752, lng: 57.70654322812745 },
                { lat: -20.30220479564131, lng: 57.650200334640594 }
            ],
            south: [
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.3047825860501, lng: 57.57183812936434 }, // near piton du millieu reservoir
                { lat: -20.355078765284823, lng: 57.574000927025814 }, // Midlands
                { lat: -20.40630811394286, lng: 57.67399679094957 }, // Grand Bel Air
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.477384205818734, lng: 57.67645174119369 }, // Mon Desert end
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.405793, lng: 57.563126 }, // La flora
                { lat: -20.41786307919899, lng: 57.46138830352348 },
                { lat: -20.463664565351785, lng: 57.45489991053907 },
                { lat: -20.46447508818725, lng: 57.39866717134076 },
                { lat: -20.500944185109162, lng: 57.39434157601781 }
            ]
        }

        // Iterate through the districtCoordinates to create a Polyline for each district
        Object.keys(districtCoordinates).forEach((district) => {
            const districtPath = districtCoordinates[district]

            const districtLine = new google.maps.Polyline({
                path: districtPath,
                geodesic: true,
                strokeColor: '#FF0000', // Red color for boundaries
                strokeOpacity: 1, // Slightly transparent
                strokeWeight: 1, // Thinner lines
                zIndex: 1 // Ensure boundaries are below markers
            })

            districtLine.setMap(map)
        })

        // Polygons used for containsLocation checks
        const districtCoordinatesPoly = {
            centre: [
                { lat: -20.463664565351785, lng: 57.45489991053907 }, // Mo'Bike Mauritius
                { lat: -20.46447508818725, lng: 57.39866717134076 }, // Near St Martin top
                { lat: -20.500944185109162, lng: 57.39434157601781 }, // BORDER SOUTH St Martin
                { lat: -20.469302, lng: 57.259107 }, // on the sea 3
                { lat: -20.211127, lng: 57.322965 }, // on the sea 2
                { lat: -20.135702, lng: 57.452349 }, // on the sea 1
                { lat: -20.12045, lng: 57.49163 }, // Tombeau bay
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.3047825860501, lng: 57.57183812936434 }, // near piton du millieu reservoir
                { lat: -20.355078765284823, lng: 57.574000927025814 }, // Midlands
                { lat: -20.40630811394286, lng: 57.67399679094957 }, // Grand Bel Air
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.405793, lng: 57.563126 }, // La flora
                { lat: -20.41786307919899, lng: 57.46138830352348 } // Near Gran bassin Temple
            ],
            north: [
                { lat: -20.12045, lng: 57.49163 }, // Tombeau bay
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.116382691420796, lng: 57.60657209403735 }, // Mon gout
                { lat: -20.017558, lng: 57.70334 }, // amber island into sea
                { lat: -19.957059, lng: 57.67936 }, // north of amber into sea
                { lat: -19.98861, lng: 57.493242 } // amber island into sea
            ],
            northEast: [
                { lat: -20.017558, lng: 57.70334 }, // amber island into sea
                { lat: -20.116382691420796, lng: 57.60657209403735 }, // Mon gout
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.205072, lng: 57.820461 }, // palmar
                { lat: -20.10093, lng: 57.781998 } // point in the sea near post lafayette
            ],
            east: [
                { lat: -20.205072, lng: 57.820461 }, // palmar in the sea
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.312118, lng: 57.744994 }, // near belle rive
                { lat: -20.30220479564131, lng: 57.77921739508874 }, // quatre soeurs
                { lat: -20.266529, lng: 57.820776 } // Point in the sea to close palmar & quatre soeurs
            ],
            centerEast: [
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.312118, lng: 57.744994 }, // near belle rive
                { lat: -20.323263830267752, lng: 57.70654322812745 }, // Domaine de l'etoile
                { lat: -20.30220479564131, lng: 57.650200334640594 }, // Near Motagne Blance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.222143169580967, lng: 57.60065863444995 } // l'esperance
            ],
            southEast: [
                { lat: -20.30220479564131, lng: 57.77921739508874 }, // quatre soeurs
                { lat: -20.323263830267752, lng: 57.70654322812745 }, // Domaine de l'etoile
                { lat: -20.30220479564131, lng: 57.650200334640594 }, // Near Motagne Blance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.3047825860501, lng: 57.57183812936434 }, // near piton du millieu reservoir
                { lat: -20.355078765284823, lng: 57.574000927025814 }, // Midlands
                { lat: -20.40630811394286, lng: 57.67399679094957 }, // Grand Bel Air
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.477384205818734, lng: 57.67645174119369 }, // Mon Desert end
                { lat: -20.489447, lng: 57.713782 }, // Mon Desert end in the sea 1
                { lat: -20.369882, lng: 57.816591 } // Mon Desert end in the sea 2
            ],
            south: [
                { lat: -20.477384205818734, lng: 57.67645174119369 }, // Mon Desert end
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.405793, lng: 57.563126 }, // La flora
                { lat: -20.41786307919899, lng: 57.46138830352348 }, // Near Gran bassin Temple
                { lat: -20.463664565351785, lng: 57.45489991053907 }, // Near Mo'Bike Mauritius Biking Trails
                { lat: -20.46447508818725, lng: 57.39866717134076 }, // left of Visitor's centre and field
                { lat: -20.500944185109162, lng: 57.39434157601781 }, // ST Martin
                { lat: -20.523815, lng: 57.424365 }, // in the sea south
                { lat: -20.531757, lng: 57.576712 } // in the sea south
            ]
        }

        const polygons = {}

        // Create polygons for each key in districtCoordinatesPoly
        Object.keys(districtCoordinatesPoly).forEach((districtKey) => {
            if (districtCoordinatesPoly[districtKey].length) {
                const polygon = new google.maps.Polygon({
                    path: districtCoordinatesPoly[districtKey],
                    geodesic: true,
                    strokeColor: '#03045E',
                    strokeOpacity: 0,
                    strokeWeight: 1,
                    fillOpacity: 0,
                    zIndex: 1
                })
                polygon.setMap(map)
                polygons[districtKey] = polygon
            }
        })

        return polygons
    }

    isCoordinateInAnyPolygon(coordinate, polygons) {
        return Object.keys(polygons).some((district) =>
            google.maps.geometry.poly.containsLocation(
                coordinate,
                polygons[district]
            )
        )
    }
}
