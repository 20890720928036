import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["name", "color", "modal", "error", "success", "tagContainer"]

  connect() {
    // console.log("TagController connected")
    this.loadTags()
  }

  loadTags() {
    const bookingId = this.data.get("bookingId")
    const url = bookingId ? `/tags?booking_id=${bookingId}` : '/tags'

    // Clear the tag container before loading new tags
    this.tagContainerTarget.innerHTML = ""

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response => response.json())
      .then(tags => {
        const selectedTags = JSON.parse(this.tagContainerTarget.dataset.selectedTags || "[]")
        tags.forEach(tag => {
          const tagClass = `tag-${tag.name.toLowerCase().replace(/[^a-z0-9]/g, '-')}`
          const isChecked = selectedTags.includes(tag.name) ? 'checked' : ''

          // Exclusion list for tags that cannot be deleted
          const excludedTags = ["Urgent", "-10%", "-20%", "FOC", "VIP"]

          // Determine if delete button should be shown
          const canDelete = !excludedTags.includes(tag.name) && !tag.associated

          const deleteButtonHTML = canDelete ? `
            <button type="button" class="btn btn-sm text-danger p-0 delete-tag" data-action="click->tag#deleteTag" title="Delete Tag" aria-label="Delete Tag ${tag.name}"><i class="fa-solid fa-trash"></i></button>
          ` : ''

          const newTagHTML = `
            <div class="tag-item ${tagClass}" data-tag-id="${tag.id}">
              <input type="checkbox" name="booking[tag_list][]" value="${tag.name}" id="booking_tag_list_${tag.name.toLowerCase()}" class="form-check-input" ${isChecked}>
              <label for="booking_tag_list_${tag.name.toLowerCase()}" class="form-check-label">${tag.name}</label>
              ${deleteButtonHTML}
            </div>
          `
          this.tagContainerTarget.insertAdjacentHTML('beforeend', newTagHTML)
          this.applyTagColor(tagClass, tag.color)
        })
      })
      .catch(error => {
        console.error('Error loading tags:', error)
        this.showErrorAlert('Failed to load tags.')
      })
  }

  applyTagColor(tagClass, tagColor) {
    const style = document.createElement('style')
    style.innerHTML = `
      .${tagClass} input:checked + label {
        background-color: ${tagColor};
      }
    `
    document.head.appendChild(style)
  }

  createTag(event) {
    event.preventDefault()

    const tagName = this.nameTarget.value
    const tagColor = this.colorTarget.value

    console.log('Creating tag:', tagName, tagColor)

    // Perform AJAX request to create new tag
    fetch('/tags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        tag: {
          name: tagName,
          color: tagColor
        }
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return response.json().then(errorData => {
            throw new Error(errorData.errors.join(', '))
          })
        }
      })
      .then(data => {
        console.log('Response data:', data) // Debugging statement

        if (data.success) {
          const tag = data.tag
          const tagClass = `tag-${tag.name.toLowerCase().replace(/[^a-z0-9]/g, '-')}`
          const excludedTags = ["Urgent", "-10%", "-20%", "FOC", "VIP"]
          const canDelete = !excludedTags.includes(tag.name)

          const deleteButtonHTML = canDelete ? `
            <button type="button" class="btn btn-sm text-danger p-0 delete-tag" data-action="click->tag#deleteTag" title="Delete Tag" aria-label="Delete Tag ${tag.name}"><i class="fa-solid fa-trash"></i></button>
          ` : ''

          const newTagHTML = `
            <div class="tag-item ${tagClass}" data-tag-id="${tag.id}">
              <input type="checkbox" name="booking[tag_list][]" value="${tag.name}" id="booking_tag_list_${tag.name.toLowerCase()}" class="form-check-input">
              <label for="booking_tag_list_${tag.name.toLowerCase()}" class="form-check-label">${tag.name}</label>
              ${deleteButtonHTML}
            </div>
          `
          this.tagContainerTarget.insertAdjacentHTML('beforeend', newTagHTML)
          this.applyTagColor(tagClass, tag.color)

          this.showSuccessAlert('Tag created successfully.')

          setTimeout(() => {
            this.modalTarget.classList.remove('show')
            this.modalTarget.style.display = 'none'

            const body = document.querySelector('body')
            body.classList.remove('modal-open')
            body.style.overflow = 'auto'
            body.style.paddingRight = '0'
            const modalBackdrop = document.querySelector('.modal-backdrop')
            if (modalBackdrop) {
              modalBackdrop.parentNode.removeChild(modalBackdrop)
            }

            this.nameTarget.value = ""
            this.colorTarget.value = "#3cb7d1"
          }, 2000);
        } else {
          console.error('Error adding tag:', data.errors)
          this.showErrorAlert(data.errors.join(', '))
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showErrorAlert(error.message || 'An unexpected error occurred. Please try again.')
      })
  }

  deleteTag(event) {
    event.preventDefault()

    const tagElement = event.currentTarget.closest('.tag-item')
    const tagId = tagElement.dataset.tagId

    if (!tagId) {
      console.error('Tag ID not found.')
      return
    }

    if (!confirm('Are you sure you want to delete this tag? This action cannot be undone.')) {
      return
    }

    fetch(`/tags/${tagId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          tagElement.remove()
          this.showSuccessAlert('Tag deleted successfully.')
        } else {
          console.error('Error deleting tag:', data.errors)
          this.showErrorAlert(data.errors.join(', '))
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.showErrorAlert('An unexpected error occurred while deleting the tag.')
      })
  }

  showSuccessAlert(message) {
    const alertHTML = `
      <div class="alert alert-success alert-dismissible fade show" role="alert">
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `
    // Insert the alert at the top of the tagContainer
    this.tagContainerTarget.insertAdjacentHTML('afterbegin', alertHTML)
  }

  showErrorAlert(message) {
    const alertHTML = `
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `
    // Insert the alert at the top of the tagContainer
    this.tagContainerTarget.insertAdjacentHTML('afterbegin', alertHTML)
  }
}
