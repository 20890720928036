import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="select-patient-nav"
export default class extends Controller {
    static get targets() {
        return ['myInput', 'patientVerification']
    }

    static values = {
        patientId: Number // New value for patient ID
    }

    initialize() {
        this.fieldIds = {
            firstName: 'patientFirstName',
            lastName: 'patientLastName',
            mobileNumber: 'patientMobileNumber',
            gender: 'patientGender',
            dateOfBirth: 'patientDateOfBirth',
            email: 'patientEmail',
            age: 'patientAge',
            address: 'patientAddress',
            tagList: 'patientTagList'
        }
    }

    async connect() {
        console.log('select patient nav connected')

        if (this.hasMyInputTarget) {
            // Initialize TomSelect if the <select> exists (new booking)
            const select = new TomSelect(this.myInputTarget, {
                valueField: 'value',
                labelField: 'text',
                sortField: 'text',
                allowEmptyOption: true,
                placeholder: 'Search for a patient...',
                searchField: [
                    'firstName',
                    'lastName',
                    'mobileNumber',
                    'nId',
                    'text'
                ],

                render: {
                    item: (data) => {
                        // Check if firstName and lastName exist
                        if (data.firstName && data.lastName) {
                            return `<div class="item">${data.lastName} ${data.firstName}</div>`
                        }
                        // Fallback to text
                        return `<div class="item">${data.text}</div>`
                    },
                    option: (data) => {
                        // Check if firstName and lastName exist
                        if (data.firstName && data.lastName) {
                            return `<div class="option">${data.lastName} ${data.firstName}</div>`
                        }
                        // Fallback to text
                        return `<div class="option">${data.text}</div>`
                    },
                    no_results: () => {
                        const wrapper = document.createElement('div')
                        wrapper.className = 'ts-no-results'
                        wrapper.innerHTML = `
                            <p class="mx-0 my-0">No patients found.</p>
                            <a href="/patients/new" class="btn-add-patient">+ Add New Patient</a>
                        `
                        wrapper
                            .querySelector('a')
                            .addEventListener('click', (event) => {
                                event.preventDefault()
                                window.location.href = '/patients/new'
                            })
                        return wrapper
                    }
                },

                /**
                 * The critical piece:
                 * This callback is called whenever an <option> is parsed and added to TomSelect.
                 * We take the data-* attributes from the option DOM element and put them onto the item object.
                 */
                onOptionAdd: (value, optionElement) => {
                    if (!optionElement) {
                        console.warn(
                            'Option Element is undefined for value:',
                            value
                        )
                        return
                    }

                    const item = select.options[value]

                    // Copy data attributes from <option data-first-name="..."> => item.firstName
                    if (optionElement.dataset.firstName) {
                        // 'data-first-name' maps to 'dataset.firstName'
                        item.firstName = optionElement.dataset.firstName
                    }

                    if (optionElement.dataset.lastName) {
                        // 'data-last-name' maps to 'dataset.lastName'
                        item.lastName = optionElement.dataset.lastName
                    }

                    if (optionElement.dataset.mobileNumber) {
                        // 'data-mobile-number' maps to 'dataset.mobileNumber'
                        item.mobileNumber = optionElement.dataset.mobileNumber
                    }

                    if (optionElement.dataset.nId) {
                        // 'data-n-id' maps to 'dataset.nId'
                        item.nId = optionElement.dataset.nId
                    }

                    // Optionally, create a fullName field if needed
                    item.fullName = `${item.firstName} ${item.lastName}`
                }
            })

            // Listen for changes in selection
            select.on('change', async (value) => {
                try {
                    const patientData = await this.findPatient(value)
                    this.updatePatientFields(patientData)
                    this.updateAddress(
                        this.myInputTarget.options[
                            this.myInputTarget.selectedIndex
                        ]
                    )

                    if (value) {
                        window.location.href = `/patients/${value}`
                    }
                } catch (error) {
                    console.error('Error:', error)
                }
            })

            // Fetch and display patient details if a patient is already selected (e.g., in edit mode)
            const selectedPatientId = this.myInputTarget.value
            if (selectedPatientId) {
                this.handlePatientChange(selectedPatientId)
            }
        } else if (this.hasPatientIdValue && this.patientIdValue > 0) {
            this.fetchAndDisplayPatientDetails(this.patientIdValue)
        }
    }

    // Handle changes in patient selection
    handlePatientChange = async (value) => {
        try {
            const patientData = await this.findPatient(value)
            this.updatePatientFields(patientData)
        } catch (error) {
            console.error('Error fetching patient data:', error)
        }
    }

    // Update patient-related fields based on selected patient data
    updatePatientFields(patientData) {
        Object.entries(this.fieldIds).forEach(([field, elementId]) => {
            const element = document.getElementById(elementId)
            if (
                element &&
                patientData[field] !== undefined &&
                patientData[field] !== null
            ) {
                element.value = patientData[field]
            } else if (element) {
                element.value = '' // Clear the field if data is missing
            }
        })
    }

    // Update address based on selected patient
    updateAddress(item) {
        const address = item.dataset.address
        const addressInput = document.getElementById('address-input')
        if (addressInput) {
            addressInput.value = address
        }
    }

    // Fetch detailed information about a specific patient
    async findPatient(patientId) {
        if (
            !patientId ||
            !this.hasPatientVerificationTarget ||
            this.patientVerificationTarget.id !== 'patient'
        ) {
            return {}
        }

        let url = `/patients/${patientId}/data_patient`

        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.status}`
                )
            }
            const data = await response.json()
            if (data.error) {
                throw new Error(`Server error: ${data.error}`)
            }

            return data
        } catch (error) {
            console.error('Error fetching patient details:', error)
            return {}
        }
    }

    // Fetch and display patient details when editing
    async fetchAndDisplayPatientDetails(patientId) {
        try {
            const patientData = await this.findPatient(patientId)
            this.updatePatientFields(patientData)
        } catch (error) {
            console.error('Error fetching patient details:', error)
            const patientDetailsDiv = document.getElementById(
                'booking--patient-details'
            )
            if (patientDetailsDiv) {
                patientDetailsDiv.innerHTML =
                    "<p class='error'>Unable to fetch patient details. Please try again later.</p>"
            }
        }
    }
}
