import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="dynamic-load-booking"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.startAutoRefresh();
    this.restoreScrollPosition();
    window.addEventListener('beforeunload', this.saveScrollPosition);
  }

  disconnect() {
    this.stopAutoRefresh();
    window.removeEventListener('beforeunload', this.saveScrollPosition);
  }

  async refresh() {
    let link = this.containerTarget.baseURI;
    const url = new URL(link);
    const endPart = url.pathname; // This will give you "/bookings"

    // Get the current page parameter
    const currentPage = url.searchParams.get("page") || 1;

    try {
      const response = await fetch(`${endPart}?page=${currentPage}`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      });

      if (response.ok) {
        const html = await response.text();
        this.containerTarget.innerHTML = html;
        // console.log("Bookings updated");
        this.restoreScrollPosition(); // Restore scroll position after content update
      } else {
        // console.log("Failed to refresh bookings. Response not OK:", response);
      }
    } catch (error) {
      console.error("Error refreshing bookings:", error);
    }
  }

  startAutoRefresh() {
    this.refreshInterval = setInterval(() => {
      this.refresh();
    }, 10000); // Auto-refresh every 10 seconds
    // console.log("Auto-refresh started");
  }

  stopAutoRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      // console.log("Auto-refresh stopped");
    }
  }

  saveScrollPosition() {
    sessionStorage.setItem('scrollPosition', window.scrollY);
  }

  restoreScrollPosition() {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    }
  }
}
