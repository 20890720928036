import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="closest-doctors"
export default class extends Controller {
    static targets = [
        'doctorSelect',
        'doctorVerification',
        'dayOfAppointment',
        'addressInput'
    ]
    static values = {
        api: String,
        doctorId: Number
    }

    tomSelectInstance = null
    doctorsInfo = []
    doctorFieldIds = {}
    addressProvided = false

    // Define onlineStatuses as a class property
    onlineStatuses = [
        'online',
        'on_duty',
        'on_call',
        'stand_by',
        'exceptional',
        'midnight',
        'day_duty'
    ]

    // Convert snake_case to Title Case (e.g., 'on_duty' -> 'On Duty')
    titleCase = (str = '') => {
        if (typeof str !== 'string') {
            console.warn(`Expected a string but received ${typeof str}:`, str)
            return ''
        }
        return str
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    // Map statuses to colors based on user.rb's status_to_css
    getStatusColor = (status) => {
        switch (status) {
            case 'online':
            case 'on_duty':
            case 'on_call':
            case 'stand_by':
            case 'exceptional':
            case 'midnight':
            case 'day_duty':
                return 'green' // Corresponds to 'text-bg-success'
            case 'off_duty':
            case 'offline':
                return 'red' // Corresponds to 'text-bg-secondary'
            case null:
                return 'gray' // Corresponds to 'text-bg-danger'
            default:
                return 'black' // Corresponds to 'text-bg-dark' or other statuses
        }
    }

    async connect() {
        await this.fetchDoctorsList()

        // Sort doctors: prioritize 'online' statuses first
        this.sortDoctorsInitial()

        if (this.hasDoctorSelectTarget) {
            // Initialize TomSelect if doctorSelect target exists
            this.initializeTomSelect()

            // Build initial doctor options with sorted doctors
            this.buildDoctorOptions(this.doctorsInfo, false) // Initial load, address not provided

            // Attach event listener to the day of appointment field
            if (this.hasDayOfAppointmentTarget) {
                this.dayOfAppointmentTarget.addEventListener(
                    'change',
                    this.updateDoctorsBasedOnAppointmentDate
                )
            }

            // Handle doctor selection changes
            this.tomSelectInstance.on('change', this.handleDoctorChange)
        } else if (this.hasDoctorIdValue) {
            // If in edit mode, fetch and display doctor details
            this.fetchAndDisplayDoctorDetails(this.doctorIdValue)
        }
    }

    initializeTomSelect() {
        this.tomSelectInstance = new TomSelect(this.doctorSelectTarget, {
            allowEmptyOption: true,
            optgroupField: 'group',
            optgroupLabelField: 'name',
            optgroupValueField: 'id',
            labelField: 'name',
            valueField: 'id',
            searchField: ['name'],
            render: {
                option: (data, escape) => `
                    <div>
                        <span class="doctor-name">${escape(data.name)}</span>
                        <div>
                            ${this.onlineStatuses.includes(data.status) && data.region
                                        ? `<span class="doctor-region" style="color: #6c757d;">${escape(data.region)}</span>`
                                        : ''
                                    }
                            <span class="doctor-status" style="color:${escape(data.statusColor)}; margin-left: 10px;">
                                ${escape(this.titleCase(data.status))} ${data.extraTag ? '[' + escape(data.extraTag) + ']' : ''} ●
                            </span>
                        </div>
                    </div>`,
                item: (data, escape) => `
                    <div>
                        <span class="doctor-name">${escape(data.name)}</span>
                        <div>
                            ${this.onlineStatuses.includes(data.status) && data.region
                                        ? `<span class="doctor-region" style="color: #6c757d;">${escape(data.region)}</span>`
                                        : ''
                                    }
                            <span class="doctor-status" style="color:${escape(data.statusColor)}; margin-left: 10px;">
                                ${escape(this.titleCase(data.status))} ${data.extraTag ? '[' + escape(data.extraTag) + ']' : ''} ●
                            </span>
                        </div>
                    </div>`,
                optgroup_header: (data, escape) =>
                    `<div class="optgroup-header">${escape(data.name)}</div>`
            }
        })
    }

    // Sort doctors: prioritize 'online' statuses first, then alphabetically
    sortDoctorsInitial() {
        this.doctorsInfo.sort((a, b) => {
            const onlineStatuses = this.onlineStatuses

            const aIsOnline = onlineStatuses.includes(a.doctorStatus)
            const bIsOnline = onlineStatuses.includes(b.doctorStatus)

            if (aIsOnline && !bIsOnline) return -1
            if (!aIsOnline && bIsOnline) return 1

            // If both are online or both are not, sort alphabetically by doctor name
            return a.doctor.localeCompare(b.doctor)
        })
    }

    // Handle changes in doctor selection
    handleDoctorChange = async (value) => {
        try {
            const doctorData = await this.findDoctor(value)
            this.updateDoctorFields(this.doctorFieldIds, doctorData)
        } catch (error) {
            console.error('Error fetching doctor data:', error)
        }
    }

    // Update doctor-related fields based on selected doctor data
    updateDoctorFields(fieldIds, doctorData) {
        Object.entries(fieldIds).forEach(([field, elementId]) => {
            const element = document.getElementById(elementId)
            if (
                element &&
                doctorData[field] !== undefined &&
                doctorData[field] !== null
            ) {
                element.value = doctorData[field]
            } else if (element) {
                element.value = '' // Clear the field if data is missing
            }
        })
    }

    // Fetch detailed information about a specific doctor
    async findDoctor(doctorId) {
        if (!doctorId || this.doctorVerificationTarget.id !== 'doctor') {
            return {}
        }

        const url = `/doctors/${doctorId}/data_doctor`

        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.status}`
                )
            }

            const data = await response.json()
            if (data.error) {
                throw new Error(`Server error: ${data.error}`)
            }

            this.populateDoctorDetails(data)
            return data
        } catch (error) {
            console.error('Error fetching doctor details:', error)
            return {}
        }
    }

    // Populate doctor details in the DOM
    populateDoctorDetails(data) {
        const doctorDetailsDiv = document.getElementById(
            'booking--doctor-details'
        )
        if (!doctorDetailsDiv) {
            console.warn('Doctor details container not found.')
            return
        }
        doctorDetailsDiv.innerHTML = ''

        const fieldsToUpdate = {
            firstName: 'First Name',
            lastName: 'Last Name',
            mobileNumber: 'Mobile Number',
            gender: 'Gender',
            specialization: 'Specialization',
            status: 'Status'
        }

        Object.entries(fieldsToUpdate).forEach(([key, label]) => {
            let value = data[key] ?? 'No data'
            value = key === 'status' ? this.titleCase(value) : value
            const detailElement = document.createElement('div')
            detailElement.innerHTML = `
        <p class="patient_details_label">${label}</p>
        <p class="patient_data_detail">${value}</p>
      `
            doctorDetailsDiv.appendChild(detailElement)
        })
    }

    // Handle changes in the day of appointment field
    updateDoctorsBasedOnAppointmentDate = async () => {
        await this.fetchDoctorsList()
        this.buildDoctorOptions(this.doctorsInfo, this.addressProvided)
    }

    // Fetch the list of doctors based on the appointment date
    async fetchDoctorsList() {
        const appointmentDateStr = this.getAppointmentDate().toISOString()
        const url = `/booking_docs?appointment_date=${encodeURIComponent(appointmentDateStr)}`

        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch doctors list: ${response.status}`
                )
            }
            const data = await response.json()
            this.doctorsInfo = data.markers
        } catch (error) {
            console.error('Error fetching doctors list:', error)
        }
    }

    // Get the selected appointment date
    getAppointmentDate() {
        if (
            this.hasDayOfAppointmentTarget &&
            this.dayOfAppointmentTarget.value
        ) {
            const date = new Date(this.dayOfAppointmentTarget.value)
            if (!isNaN(date)) {
                return date
            }
            console.error(
                'Invalid date format:',
                this.dayOfAppointmentTarget.value
            )
        }
        return new Date()
    }

    // Build and populate doctor options in TomSelect with three groups
    buildDoctorOptions(doctorsArray, addressProvided) {
        // Define the three groups
        const optgroups = [
            { id: 'nearest', name: 'Nearest Doctors' },
            { id: 'available', name: 'Available Doctors' },
            { id: 'all', name: 'All Doctors' }
        ]

        // Initialize options data array
        const optionsData = []

        if (addressProvided) {
            // The doctorsArray is already grouped
            // Check if there are any doctors in each group
            optgroups.forEach((group) => {
                const doctorsInGroup = doctorsArray.filter(
                    (doctor) => doctor.group === group.id
                )
                if (doctorsInGroup.length > 0) {
                    doctorsInGroup.forEach((doctor) => {
                        optionsData.push({
                            id: String(doctor.id),
                            name: doctor.doctor,
                            group: group.id,
                            status: doctor.doctorStatus,
                            statusColor: this.getStatusColor(
                                doctor.doctorStatus
                            ),
                            extraTag:
                                group.id === 'all' &&
                                ['online', 'on_duty'].includes(
                                    doctor.doctorStatus
                                ) &&
                                doctor.hasBooking
                                ? 'In Consultation'
                                : null,
                            region: doctor.region,
                        })
                    })
                } else {
                    // If no doctors in the group, add a disabled "No result found" option
                    optionsData.push({
                        id: `no-result-${group.id}`,
                        name: 'No result found',
                        group: group.id,
                        disabled: true
                    })
                }
            })
        } else {
            // Initial load: No address provided
            // Add disabled "No result, enter an address" to Nearest Doctors
            optionsData.push({
                id: 'no-result-nearest',
                name: 'No result, enter an address',
                group: 'nearest',
                disabled: true
            })

            // Proceed to add Available Doctors and All Doctors as usual
            const availableDoctors = doctorsArray.filter(
                (doctor) =>
                    !doctor.hasBooking &&
                    ['online', 'on_duty'].includes(doctor.doctorStatus)
            )

            const otherDoctors = doctorsArray.filter(
                (doctor) =>
                    doctor.hasBooking ||
                    !['online', 'on_duty'].includes(doctor.doctorStatus)
            )

            // Sort 'otherDoctors' with 'online' statuses prioritized
            otherDoctors.sort((a, b) => {
                const onlineStatuses = this.onlineStatuses
                const aIsOnline = onlineStatuses.includes(a.doctorStatus)
                const bIsOnline = onlineStatuses.includes(b.doctorStatus)

                if (aIsOnline && !bIsOnline) return -1
                if (!aIsOnline && bIsOnline) return 1

                // If both are online or both are not, sort alphabetically by doctor name
                return a.doctor.localeCompare(b.doctor)
            })

            // Add available doctors
            if (availableDoctors.length > 0) {
                availableDoctors.forEach((doctor) => {
                    optionsData.push({
                        id: String(doctor.id),
                        name: doctor.doctor,
                        group: 'available',
                        status: doctor.doctorStatus,
                        statusColor: this.getStatusColor(doctor.doctorStatus),
                        region: doctor.region,
                    })
                })
            } else {
                optionsData.push({
                    id: `no-result-available`,
                    name: 'No result found',
                    group: 'available',
                    disabled: true
                })
            }

            // Add other doctors
            if (otherDoctors.length > 0) {
                otherDoctors.forEach((doctor) => {
                    optionsData.push({
                        id: String(doctor.id),
                        name: doctor.doctor,
                        group: 'all',
                        status: doctor.doctorStatus,
                        statusColor: this.getStatusColor(doctor.doctorStatus),
                        extraTag:
                            ['online', 'on_duty'].includes(
                                doctor.doctorStatus
                            ) && doctor.hasBooking
                                ? 'In Consultation'
                                : null,
                        region: doctor.region,
                    })
                })
            } else {
                optionsData.push({
                    id: `no-result-all`,
                    name: 'No result found',
                    group: 'all',
                    disabled: true
                })
            }
        }

        const tomSelect = this.tomSelectInstance
        tomSelect.clear()
        tomSelect.clearOptions()
        tomSelect.clearOptionGroups()

        // Add each option group individually with proper parameters
        optgroups.forEach((group) => {
            if (group && group.id && group.name) {
                tomSelect.addOptionGroup(group.id, group)
            } else {
                console.warn('Invalid option group:', group)
            }
        })

        tomSelect.addOptions(optionsData)
        tomSelect.refreshOptions(false)
    }

    // Fetch and display doctor details when editing
    async fetchAndDisplayDoctorDetails(doctorId) {
        try {
            const doctorData = await this.findDoctor(doctorId)
            this.populateDoctorDetails(doctorData)
        } catch (error) {
            console.error('Error fetching doctor details:', error)
        }
    }

    // Geocode the patient's address to get coordinates
    getPatientGeocode(address) {
        const geocoder = new google.maps.Geocoder()
        return new Promise((resolve) => {
            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK' && results.length > 0) {
                    const location = results[0].geometry.location
                    resolve({ lat: location.lat(), lng: location.lng() })
                } else {
                    console.warn('Geocoding failed:', status)
                    resolve(null)
                }
            })
        })
    }

    // Calculate the distance between two points using Google Maps Distance Matrix
    getDistance(origin, destination) {
        const service = new google.maps.DistanceMatrixService()
        const originCoordinates = new google.maps.LatLng(origin.lat, origin.lng)
        const destinationCoordinates = new google.maps.LatLng(
            destination.lat,
            destination.lng
        )

        return new Promise((resolve) => {
            service.getDistanceMatrix(
                {
                    origins: [originCoordinates],
                    destinations: [destinationCoordinates],
                    travelMode: google.maps.TravelMode.DRIVING,
                    unitSystem: google.maps.UnitSystem.METRIC
                },
                (response, status) => {
                    if (status === 'OK') {
                        const element = response.rows[0].elements[0]
                        if (element.status === 'OK') {
                            resolve(element.distance.value)
                        } else if (
                            element.status === 'ZERO_RESULTS' &&
                            origin.lat === destination.lat &&
                            origin.lng === destination.lng
                        ) {
                            resolve(0)
                        } else {
                            resolve(Number.MAX_SAFE_INTEGER)
                        }
                    } else {
                        resolve(Number.MAX_SAFE_INTEGER)
                    }
                }
            )
        })
    }

    // Find and sort doctors based on patient location and appointment details
    async findDocs(event) {
        setTimeout(async () => {
            let address = ''
            // console.log('address input: ', this.addressInputTarget.value)
            if (event.target.tagName === 'SELECT') {
                // Event triggered from patient select
                const selectedOption = event.target.selectedOptions[0]
                if (selectedOption) {
                    address = selectedOption.dataset.address || ''
                } else {
                    console.warn('No option selected in the patient select.')
                    this.addressProvided = false
                    this.buildDoctorOptions(
                        this.doctorsInfo,
                        this.addressProvided
                    )
                    return
                }
            } else if (event.target.tagName === 'INPUT') {
                // Event triggered from address input
                address = event.target.value.trim()
                if (!address) {
                    console.warn('Address input is empty.')
                    this.addressProvided = false
                    this.buildDoctorOptions(
                        this.doctorsInfo,
                        this.addressProvided
                    )
                    return
                }
            } else {
                console.warn('findDocs triggered by an unknown element.')
                this.addressProvided = false
                this.buildDoctorOptions(this.doctorsInfo, this.addressProvided)
                return
            }

            const coordinates = await this.getPatientGeocode(address)

            if (!coordinates) {
                console.log(
                    'Could not get the coordinates for the address:',
                    address
                )
                this.addressProvided = false
                this.buildDoctorOptions(this.doctorsInfo, this.addressProvided)
                return
            }

            const arrayDoctorsWithDistance = await Promise.all(
                this.doctorsInfo.map(async (doctor) => {
                    if (doctor.lat && doctor.lng) {
                        try {
                            const distance = await this.getDistance(
                                coordinates,
                                doctor
                            )
                            return { ...doctor, distance }
                        } catch {
                            return {
                                ...doctor,
                                distance: Number.MAX_SAFE_INTEGER
                            }
                        }
                    }
                    return { ...doctor, distance: Number.MAX_SAFE_INTEGER }
                })
            )

            // Filter available doctors (no booking and status is 'online' or 'on_duty')
            const availableDoctors = arrayDoctorsWithDistance.filter(
                (doctor) =>
                    !doctor.hasBooking &&
                    ['online', 'on_duty'].includes(doctor.doctorStatus)
            )

            // Sort available doctors by distance ascending
            availableDoctors.sort((a, b) => a.distance - b.distance)

            // Select first 3 nearest available doctors
            const nearestDoctors = availableDoctors.slice(0, 3)

            // Remaining available doctors beyond the first 3
            const remainingAvailableDoctors = availableDoctors.slice(3)

            // All other doctors
            const otherDoctors = arrayDoctorsWithDistance.filter(
                (doctor) =>
                    doctor.hasBooking ||
                    !['online', 'on_duty'].includes(doctor.doctorStatus)
            )

            // Sort 'otherDoctors' with 'online' statuses prioritized
            otherDoctors.sort((a, b) => {
                const onlineStatuses = this.onlineStatuses
                const aIsOnline = onlineStatuses.includes(a.doctorStatus)
                const bIsOnline = onlineStatuses.includes(b.doctorStatus)

                if (aIsOnline && !bIsOnline) return -1
                if (!aIsOnline && bIsOnline) return 1

                // If both are online or both are not, sort by distance ascending
                return a.distance - b.distance
            })

            // Combine all groups with group assignment
            const groupedDoctors = [
                ...nearestDoctors.map((doctor) => ({
                    ...doctor,
                    group: 'nearest'
                })),
                ...remainingAvailableDoctors.map((doctor) => ({
                    ...doctor,
                    group: 'available'
                })),
                ...otherDoctors.map((doctor) => ({
                    ...doctor,
                    group: 'all',
                    extraTag:
                        ['online', 'on_duty'].includes(doctor.doctorStatus) &&
                        doctor.hasBooking
                            ? 'In Consultation'
                            : null,
                    region: doctor.region,
                }))
            ]

            this.addressProvided = true // Address is provided
            this.buildDoctorOptions(groupedDoctors, this.addressProvided)
        }, 500)
    }
}
