import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dynamic-load-doctors"
export default class extends Controller {
    static targets = ['container']

    connect() {
        this.renderData()
        this.updateInterval = setInterval(() => {
            this.updateData()
        }, 5000) // interval for 5 seconds
    }

    disconnect() {
        clearInterval(this.updateInterval)
    }

    async renderData() {
        try {
            let markers_data = await this.fetchData()
            this.displayMarkers(markers_data)
        } catch (error) {
            console.error('Error rendering markers_data:', error)
        }
    }

    async updateData() {
        try {
            let markers_data = await this.fetchData()
            this.displayMarkers(markers_data)
        } catch (error) {
            console.error('Error updating markers_data:', error)
        }
    }

    formatWord(word) {
        // Split the input string into an array of words based on uppercase letters
        const words = word.split(/(?=[A-Z])/)

        // Capitalize the first letter of each word
        const capitalizedWords = words.map(
            (w) => w.charAt(0).toUpperCase() + w.slice(1)
        )
        // Join them with a hyphen
        const formattedWord = capitalizedWords.join('-')

        return formattedWord
    }

    async fetchData() {
        const url = '/fetch_markers'
        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.status}`
                )
            }
            const data = await response.json()
            console.log('data fetchData', data)
            if (data.error) {
                throw new Error(`Server error: ${data.error}`)
            }
            return data.markers // Access the markers property
        } catch (error) {
            console.error('Error fetching data:', error)
            throw error // Rethrow the error to indicate failure
        }
    }

    displayMarkers(markers_data) {
        if (!Array.isArray(markers_data)) {
            console.error(
                'Expected markers_data to be an array, but got:',
                markers_data
            )
            return
        }

        const steps = {
            assigned: '1',
            acknowledge: '2',
            called_patient: '3',
            eta_set: '4',
            in_transit: '5',
            nearby: '6',
            in_progress: '7',
            completed: '8',
            rescheduled: 'RS',
            reassigned: 'RA',
            cancelled: 'C',
            extra_patient: 'EP',
            extra_time: 'ET',
            call_ambulance: 'CA',
            sms_sent: 'SS',
            unreachable: 'U'
        }

        // Region color mapping
        const regionColors = {
            centre: '#FE2718',
            north: '#16F8B8',
            northEast: '#03BCFF',
            east: '#FEAA52',
            centerEast: '#EFEE08',
            southEast: '#B56AFC',
            south: '#FD38D4'
        }

        const getTextColor = (backgroundColor) => {
            // Remove the '#' if present
            const hex = backgroundColor.replace('#', '')
            // Parse the RGB values
            const r = parseInt(hex.substring(0, 2), 16) / 255
            const g = parseInt(hex.substring(2, 4), 16) / 255
            const b = parseInt(hex.substring(4, 6), 16) / 255

            // Apply the luminance formula
            const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

            // Return 'white' if the luminance is below a threshold, else 'black'
            return luminance < 0.5 ? 'white' : 'black'
        }

        this.containerTarget.innerHTML = ''
        markers_data.forEach((marker) => {
            if (
                (marker.doctorStatus != 'offline' ||
                    marker.doctorStatus != 'off_duty') &&
                Array.isArray(marker.patients) &&
                marker.patients.length >= 1
            ) {
                const markerElement = document.createElement('div')
                markerElement.classList.add('marker')

                // Generate patient list items
                const patientListItems = marker.patients
                    .map((patient) => {
                        const statusStep = steps[patient.status] || '-'

                        // Check if bookingRegion is null and skip rendering
                        const bookingRegion = patient.bookingRegion
                            ? this.formatWord(patient.bookingRegion)
                            : ''

                        // Get background color for bookingRegion
                        const regionColor =
                            regionColors[patient.bookingRegion] || '#CCCCCC' // Default to grey if region not found

                        // Determine text color based on the background color
                        const textColor = getTextColor(regionColor);

                        return `
                            <li style="font-weight: bold; margin-bottom:2px; line-height: 1.2rem; display: flex; align-items: center; justify-content: space-between; color: ${textColor}; background-color: ${regionColor}; padding: 4px 8px; border-radius: 4px;" class="${bookingRegion}">
                              <div class="d-flex align-items-center gap-1">
                                <span style="font-size:12px;">${statusStep}</span>
                                <span class="patient-name">${patient.patient_name}</span>
                              </div>
                                <span class="patient-name">${patient.bookingTime}</span>
                            </li>`
                    })
                    .join('')

                const custom_username = marker.doctorUsername
                    ? marker.doctorUsername
                    : 'N/A'
                const currentRegionColor =
                    regionColors[marker.currentRegion] || '#CCCCCC'

                const currentRegionTextColor = getTextColor(currentRegionColor);
                markerElement.innerHTML = `
                <div id="card-${marker.doctorId}" class="doctor-card">
                  <div class="d-flex justify-content-between align-items-center" style="margin-bottom:2px;">
                    <div class="doctor-name-notice" style="font-size:12px;">
                      <p>${marker.doctorInitialsFirstname}</p>
                      <span style="font-size:14px;">(${custom_username})</span>
                    </div>
                    <p style="font-size:10px; margin:0; padding:0; background-color: ${currentRegionColor}; color: ${currentRegionTextColor}; border-radius: 4px; padding: 2px 4px;" class="${this.formatWord(marker.currentRegion)}">${this.formatWord(marker.currentRegion)}</p>
                  </div>
                  <ul>
                    ${patientListItems || '<li>No current booking</li>'}
                  </ul>
                </div>
            `
                this.containerTarget.appendChild(markerElement)
            }
        })
    }
}
