import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
    static targets = ['address', 'lon', 'lat', 'region']

    connect() {
        super.connect()
        this.map = null
        this.polygons = {}
        this.initMapAndPolygons()
    }

    // Initialize map and draw district boundaries
    initMapAndPolygons() {
        if (typeof google !== 'undefined') {
            this.map = new google.maps.Map(document.createElement('div')) // Hidden map instance
            this.polygons = this.drawDistrictBoundaries(this.map)
        } else {
            console.error('Google Maps API is not loaded.')
        }
    }

    // Override the placeChanged method
    placeChanged() {
        super.placeChanged()

        const place = this.autocomplete.getPlace()
        if (place.geometry) {
            const lat = place.geometry.location.lat()
            const lon = place.geometry.location.lng()

            // Update latitude and longitude targets
            this.latTarget.value = lat
            this.lonTarget.value = lon

            // Determine the region and update the region target
            const regionData = this.tooltipsColorCoordinates({ lat, lng: lon })
            if (this.hasRegionTarget) {
                this.regionTarget.value = regionData.region
            }
        } else {
            console.log('Selected place does not have geometry information.')
        }
    }

    // Method to draw district boundaries
    drawDistrictBoundaries(map) {
        const districtCoordinatesPoly = {
            centre: [
                { lat: -20.463664565351785, lng: 57.45489991053907 }, // Mo'Bike Mauritius
                { lat: -20.46447508818725, lng: 57.39866717134076 }, // Near St Martin top
                { lat: -20.500944185109162, lng: 57.39434157601781 }, // BORDER SOUTH St Martin
                { lat: -20.469302, lng: 57.259107 }, // on the sea 3
                { lat: -20.211127, lng: 57.322965 }, // on the sea 2
                { lat: -20.135702, lng: 57.452349 }, // on the sea 1
                { lat: -20.12045, lng: 57.49163 }, // Tombeau bay
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.3047825860501, lng: 57.57183812936434 }, // near piton du millieu reservoir
                { lat: -20.355078765284823, lng: 57.574000927025814 }, // Midlands
                { lat: -20.40630811394286, lng: 57.67399679094957 }, // Grand Bel Air
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.405793, lng: 57.563126 }, // La flora
                { lat: -20.41786307919899, lng: 57.46138830352348 } // Near Gran bassin Temple
            ],
            north: [
                { lat: -20.12045, lng: 57.49163 }, // Tombeau bay
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.116382691420796, lng: 57.60657209403735 }, // Mon gout
                { lat: -20.017558, lng: 57.70334 }, // amber island into sea
                { lat: -19.957059, lng: 57.67936 }, // north of amber into sea
                { lat: -19.98861, lng: 57.493242 } // amber island into sea
            ],
            northEast: [
                { lat: -20.017558, lng: 57.70334 }, // amber island into sea
                { lat: -20.116382691420796, lng: 57.60657209403735 }, // Mon gout
                { lat: -20.122516571262654, lng: 57.58044205573713 }, // water's edge banquet Hall
                { lat: -20.222143169580967, lng: 57.60065863444995 }, // l'esperance
                { lat: -20.205072, lng: 57.820461 }, // palmar
                { lat: -20.10093, lng: 57.781998 } // point in the sea near post lafayette
            ],
            east: [
                { lat: -20.205072, lng: 57.820461 }, // palmar in the sea
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.312118, lng: 57.744994 }, // near belle rive
                { lat: -20.30220479564131, lng: 57.77921739508874 }, // quatre soeurs
                { lat: -20.266529, lng: 57.820776 } // Point in the sea to close palmar & quatre soeurs
            ],
            centerEast: [
                { lat: -20.213553, lng: 57.72362 }, // near camp ithier
                { lat: -20.312118, lng: 57.744994 }, // near belle rive
                { lat: -20.323263830267752, lng: 57.70654322812745 }, // Domaine de l'etoile
                { lat: -20.30220479564131, lng: 57.650200334640594 }, // Near Motagne Blance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.222143169580967, lng: 57.60065863444995 } // l'esperance
            ],
            southEast: [
                { lat: -20.30220479564131, lng: 57.77921739508874 }, // quatre soeurs
                { lat: -20.323263830267752, lng: 57.70654322812745 }, // Domaine de l'etoile
                { lat: -20.30220479564131, lng: 57.650200334640594 }, // Near Motagne Blance
                { lat: -20.304054183759483, lng: 57.598374103536294 }, // Dubreuil
                { lat: -20.3047825860501, lng: 57.57183812936434 }, // near piton du millieu reservoir
                { lat: -20.355078765284823, lng: 57.574000927025814 }, // Midlands
                { lat: -20.40630811394286, lng: 57.67399679094957 }, // Grand Bel Air
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.477384205818734, lng: 57.67645174119369 }, // Mon Desert end
                { lat: -20.489447, lng: 57.713782 }, // Mon Desert end in the sea 1
                { lat: -20.369882, lng: 57.816591 } // Mon Desert end in the sea 2
            ],
            south: [
                { lat: -20.477384205818734, lng: 57.67645174119369 }, // Mon Desert end
                { lat: -20.452324205818734, lng: 57.67545174119369 }, // Mon Desert
                { lat: -20.405793, lng: 57.563126 }, // La flora
                { lat: -20.41786307919899, lng: 57.46138830352348 }, // Near Gran bassin Temple
                { lat: -20.463664565351785, lng: 57.45489991053907 }, // Near Mo'Bike Mauritius Biking Trails
                { lat: -20.46447508818725, lng: 57.39866717134076 }, // left of Visitor's centre and field
                { lat: -20.500944185109162, lng: 57.39434157601781 }, // ST Martin
                { lat: -20.523815, lng: 57.424365 }, // in the sea south
                { lat: -20.531757, lng: 57.576712 } // in the sea south
            ]
        }

        const polygons = {}

        Object.keys(districtCoordinatesPoly).forEach((districtKey) => {
            if (districtCoordinatesPoly[districtKey].length) {
                const polygon = new google.maps.Polygon({
                    path: districtCoordinatesPoly[districtKey],
                    geodesic: true,
                    strokeColor: '#03045E',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillOpacity: 0.1,
                    fillColor: '#03045E',
                    zIndex: 1
                })
                polygon.setMap(map)
                polygons[districtKey] = polygon
            }
        })

        return polygons
    }

    // Method to determine the region based on the coordinates
    tooltipsColorCoordinates(coordinates) {
        let data = { colorUrl: 'outside_polygon_color_url', region: 'outside' }

        for (const [districtName, polygon] of Object.entries(this.polygons)) {
            if (
                google.maps.geometry.poly.containsLocation(
                    new google.maps.LatLng(coordinates.lat, coordinates.lng),
                    polygon
                )
            ) {
                data.colorUrl = 'inside_polygon_color_url'
                data.region = districtName // Set the region name
                break
            }
        }

        return data
    }

    // Getter for Autocomplete options
    get autocompleteOptions() {
        return {
            componentRestrictions: { country: 'MU' },
            fields: ['address_components', 'geometry', 'icon', 'name'],
            strictBounds: false
        }
    }
}
