import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="masonry"
export default class extends Controller {
  static targets = ["masonryContainer", "sidebarToggle"];

  connect() {
    console.log("Masonry controller connected!");
    this.updateMasonryClass();
  }

  updateMasonryClass() {
    const sidebar = document.querySelector("#collapseWidthSidebar");
    console.log("sidebar classlist", sidebar.classList )
    if (sidebar && sidebar.classList.contains("show")) {
      this.masonryContainerTarget.classList.remove("columns");
    } else {
      this.masonryContainerTarget.classList.add("columns");
    }
  }

  toggleSidebarClass() {
    console.log("button clicked")
    // Wait a tick for Bootstrap to toggle the `show` class
    setTimeout(() => {
      this.updateMasonryClass();
    }, 400);
  }
}
